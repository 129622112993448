<template>
  <section>
    <div class="content-header">
      <h2>Works cited </h2>
    </div>
    <div class="content-wrapper">
      <ul>
        <li class="pb-3"><em>How to Help Students Feel a Sense of Belonging During the Pandemic</em>. (2021, January 19). Greater Good. <a href="https://greatergood.berkeley.edu/article/item/how_to_help_students_feel_a_sense_of_belonging_during_the_pandemic" target="_blank">greatergood.berkeley.edu/article/item/how_to_help_students_feel_a_sense_of_belonging_during_the_pandemic</a></li>
        <li class="pb-3">Murphy, M. C., Gopalan, M., Carter, E. R., Emerson, K. T. U., Bottoms, B. L., & Walton, G. M. (2020). A customized belonging intervention improves retention of socially disadvantaged students at a broad-access university. <em>Science Advances, 6</em>(29). <a href="https://doi.org/10.1126/sciadv.aba4677" target="_blank">doi.org/10.1126/sciadv.aba4677</a></li>
        <li class="pb-3">Western University. 2018. <a href="https://teaching.uwo.ca/pdf/teaching/Guide-for-Working-with-Indigenous-Students.pdf" target="_blank"><em>Guide for working with Indigenous students</em></a>. London, ON: Western University. </li>
        <li class="pb-3"><em>Building Community in Large Classes</em>. (2019, March 1). Centre for Teaching Excellence.  <br><a href="https://uwaterloo.ca/centre-for-teaching-excellence/teaching-resources/teaching-tips/creating-positive-learning-environment/inclusivity-accessibility-and-motivation/building-community-large-classes" target="_blank">uwaterloo.ca/centre-for-teaching-excellence/teaching-resources/teaching-tips/creating-positive-learning-environment/inclusivity-accessibility-and-motivation/building-community-large-classes</a></li>
        <li class="pb-3"><em>Building a Healthy Class Environment</em>. (n.d.). SFU Health & Counselling Health Promotion.  <a href="https://www.sfu.ca/content/dam/sfu/healthycampuscommunity/wle2/brandedtools/Building%20a%20Healthy%20Class%20Environment%20Branded.pdf" target="_blank">www.sfu.ca/content/dam/sfu/healthycampuscommunity/wle2/brandedtools/Building-a-Healthy-Class-Environment-Branded.pdf</a></li>
        <li class="pb-3">Huguenin, M. (2020). <em>Trauma-Informed Teaching in Remote Courses - Teaching & Learning - Trent University</em>. Trent University. <a href="https://www.trentu.ca/teaching/node/389" target="_blank">www.trentu.ca/teaching/node/389</a></li>
        <li class="pb-3">Huguenin, M. (2020a). <em>Guidelines for Talking about Difficult Topics in a Remote Course - Teaching & Learning - Trent University</em>. Trent University. <br><a href="https://www.trentu.ca/teaching/node/391" target="_blank">www.trentu.ca/teaching/node/391</a></li>
        <li class="pb-3">Covey, S. R. (2020). <em>The 7 Habits of Highly Effective People: 30th Anniversary Edition</em> (4th ed.). Simon & Schuster.</li>
        <li class="pb-3">Centennial College. (2021). <em>Recognize, Respond, Connect Resource Guide.</em></li>
        <li class="pb-3">Trent University. (2021). <em>Responding to Students in Distress</em>.</li>
        <li class="pb-3">Robinson, N. (2017). Scripts for managing class discussion conflicts [Presentation handout]. Trent University.</li>
        <li class="pb-3">Robinson, N. (2021). Class 10 - Moving toward restorative justice. Trent Swansea Law Arts & Business, Trent University.</li>
        <li>Cormier, R. (2002). <em>Restorative Justice: Directions and Principles — Developments in Canada</em>. Solicitor General Canada.</li>
      </ul>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
